@media screen and (max-width: 800px) { 
  html {
    font-size: 2vw;
  }
}

.wrap {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.blog-image {
    width: 1em;
    height: 1em;
    object-fit: cover;
}